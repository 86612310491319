<template>
  <div>
    <div class="card p-2 bg-card">
       <div class="row">
        <div class="col-12">
          <h3 class="ml-1 mt-1" style="color:#fff;">
            {{ companydetail.name }}
          </h3>

          <button
            class="btn btn-primary btn-sm float-right mt-1 mr-1 mb-1"
            @click="assignExistingUser()"
          >
            Assign an Existing User
          </button>
          <button
            class="btn btn-warning btn-sm float-right mt-1 mr-1 mb-1"
            @click="assignUser()"
            :disabled="!this.$store.state.auth.permissions.includes('customer_user-create')"
          >
            Assign a User
          </button>
        </div>
      </div>
    </div>
    <div class="card" v-if="this.$store.state.auth.permissions.includes('customer_user-list')">
     

      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Role</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in companyusers" :key="user._id">
            <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
            <td>{{ user.firstname }} {{ user.lastname }}</td>
            <td>{{ user.email }}</td>
            <td>
              <p v-for="role in user.roles" :key="role._id">{{ role.name }}</p>
            </td>
            <td>
              <button
                class="btn btn-primary btn-sm mr-1"
                @click="invite(user.user_id)"
                v-if="!user.is_previously_logined"
                
              >
                Invite
              </button>
              <span
                @click="openremoveuser(user.user_id)"
                class="icon-box-new"
                v-if="user.type == 'GRC_user'"
                
              >
                <feather-icon
                  class="text-danger pointer rotate"
                  icon="Trash2Icon"
                  size="20"
                />
              </span>
              <!-- <button
              class="btn btn-warning btn-sm"
              @click="assignUser(company._id)"
            >
              Assign a User
            </button> -->
            </td>
          </tr>
        </tbody>
      </table>
       <div class="row">
      <div class="col-12 float-right">
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          hide-goto-end-buttons
          :total-rows="lastPage * perPage"
          class="float-right"
        />
      </div>
    </div>
      <b-modal ref="modal-adduser" title="Assign VM User " hide-footer>
        <!-- {{assignusers}} -->
        <!-- <v-select
        v-model="selecteduser"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="title"
        :options="optionuser"
        
      /> -->
        <label for="">User</label>
        <select name="" id="" v-model="selecteduser" class="form-control">
          <option
            :value="user.user_id"
            v-for="user in this.assignusers"
            :key="user._id"
          >
            {{ user.firstname }} {{ user.lastname }}
          </option>
        </select>

        <b-form-group label="Role" v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="roles"
            :options="options"
            :aria-describedby="ariaDescribedby"
            name="flavour-1"
          ></b-form-checkbox-group>
        </b-form-group>
        <!-- {{ selecteduser }} -->

        <button class="btn btn-primary btn-sm mt-1" @click="assignuser()"  :disabled="!this.$store.state.auth.permissions.includes('customer_user-create')">
          Assign User
        </button>
      </b-modal>
      <b-modal
        ref="modal-addexistinguser"
        title="Assign Existing User "
        hide-footer
      >
        <label for="">Existing User</label>
        <select name="" id="" v-model="selectedexistuser" class="form-control">
          <option
            :value="user._id"
            v-for="user in this.assignexistusers"
            :key="user._id"
          >
            {{ user.firstname }} {{ user.lastname }}
          </option>
        </select>
        
        <b-form-group label="Role" v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="rolesexist"
            :options="optionsexist"
            :aria-describedby="ariaDescribedby"
            name="flavour-1"
          ></b-form-checkbox-group>
        </b-form-group>

        <button
          class="btn btn-primary btn-sm mt-1"
          @click="assignexistinguser()"
        >
          Assign User
        </button>
      </b-modal>
    </div>
    <!-- <div class="card p-2" v-else>
      <p>You dont have the permission to view the users.</p>
    </div> -->
  </div>
</template>
<script>
import {
  BSidebar,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormCheckboxGroup,
  BPagination
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  data() {
    return {
      customerid: "",
      tenantid: "",
      companyusers: [],
      tenantid: "",
      add_user: false,
      required,
      email,
      fname: "",
      lname: "",
      companyname: "",
      emailid: "",
      phonenumber: "",
      should_invite: "",
      options: [
        { text: "Admin", value: "admin" },
        { text: "User", value: "user" },
        { text: "CISO", value: "ciso" },
        { text: "VM Consultant", value: "vm_consultant" },
      ],
      optionsexist: [
        { text: "Admin", value: "admin" },
        { text: "User", value: "user" },
      ],
      rolesexist: [],
      selectedexistuser:'',
      tid:'',
      assignexistusers:[],

      companydetail: [],
      currentPage: 1,
      lastPage: 1,
      perPage: 10,
      selecteduser:'',
      assignusers:[],
    };
  },
  components: {
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup,
    BPagination
  },
  watch: {
    $route(to, from) {
      this.customerid = this.$route.params.id;
      console.log(this.customerid);
      this.getMembers(this.customerid);
    },
     currentPage(value) {
      this.getCompanyMembers(value);
    },
  },
  mounted() {
    this.getMembers(this.customerid);
    this.getUsers();
    // this.getExistingUsers(this.tid);
  },
  
  methods: {
     assignexistinguser() {
      // const data = {
      //   sso_id: this.selectedexistuser,
      //   roles[]: this.rolesexist,
      // };
      const formData = new FormData();
      formData.append("sso_id", this.selectedexistuser);
      formData.append("add_missing_customer", 1);
      formData.append("roles[]",this.rolesexist);
      const options = {
        method: "POST",
        data: formData,
        headers: { "content-type": "application/json", cid: this.tenantid },
        url: process.env.VUE_APP_CISOGRC_API_URL + `/add/sso-user`,
      };
      this.$http(options)
        .then((res) => {
          this.$refs["modal-addexistinguser"].hide();
          this.getMembers(this.customerid);
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    assignuser() {
      const data = {
        tenant_id: this.tenantid,
        user_id: this.selecteduser,
        roles: this.roles,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/customers/${this.tenantid}/assign_vm_user`,
      };
      this.$http(options)
        .then((res) => {
          this.$refs["modal-adduser"].hide();
          this.selecteduser = "";
          this.roles = "";
          this.getMembers(this.customerid);
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    assignUser(id) {
      //   this.viewDetailsCompany(id);
      this.$refs["modal-adduser"].show();
    },
    assignExistingUser(id) {
      //   this.viewDetailsCompany(id);
      this.$refs["modal-addexistinguser"].show();
    },
    openremoveuser(uid) {
      const data = {
        tenant_id: this.tenantid,
        user_id: uid,
        // roles: this.roles,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/customers/${this.tenantid}/remove_vm_user`,
      };
      this.$http(options)
        .then((res) => {
          this.getMembers(this.customerid);
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.AddUser();
        }
      });
    },
    getMembers(id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/companies/${this.$route.params.id}`,
      };
      this.$http(options)
        .then((res) => {
          //   this.companyusers = res.data;
          this.companydetail = res.data.data;
          this.tenantid = res.data.data.tenant_uid;
          
          this.getCompanyMembers(this.currentPage);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCompanyMembers(pageno) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/customers/${this.tenantid}`,
         params: { page: pageno, limit: this.perPage },
      };
      this.$http(options)
        .then((res) => {
          this.companyusers = res.data.data.data;
          this.currentPage = res.data.data.current_page;
          this.lastPage = res.data.data.last_page;
          this.perPage = res.data.data.per_page;
          //   this.tenantid = res.data.data.company.tenant_uid
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openAddUser() {
      this.add_user = true;
    },
    closeAdduser() {
      this.add_user = false;
    },
    AddUser() {
      const data = {
        company: this.companyname,
        firstname: this.fname,
        lastname: this.lname,
        email: this.emailid,
        mobile: this.phonenumber,
        should_invite: this.should_invite,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_CISOGRC_API_URL + `/customer/sign-up`,
      };
      this.$http(options)
        .then((res) => {
          this.closeAdduser();
          this.getMembers(this.customerid);
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    invite(uid) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL + `/user/reinvite?user_id=${uid}`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUsers() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/user/search`,
      };
      this.$http(options)
        .then((res) => {
          this.assignusers = res.data.data;
          for (let i in this.assignusers) {
            let user = {
              title: value,
            };
            this.optionuser.push(user);
          }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getExistingUsers(tenantid) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json", cid: tenantid },
        url: process.env.VUE_APP_CISOGRC_API_URL + `/sso-user/list`,
      };
      this.$http(options)
        .then((res) => {
          this.assignexistusers = res.data.data;
          // for (let i in this.assignusers) {
          //   let user = {
          //     title: value,
          //   };
          //   this.optionexistuser.push(user);
          // }

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
th{
  background: #472183 !important;
  color: #fff;
}
</style>
